import React, { useEffect, useState, useRef } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useSelector } from "react-redux";
import axios from "axios";
import CustomAlert from "../../components/CustomAlert";
import UseLocalStorageListener from "../../hooks/useLocalStorage";

const reportId = process.env.REACT_APP_MULTI_YEAR_INITIATIVE_BY_LEVER_REPORT_ID;
const groupId = process.env.REACT_APP_PBI_GROUP_ID;

const MultiYearInitiativesByLever = () => {
  const [PBItokens, setPBItokens] = useState({});
  const [filters, setFilters] = useState([]);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const localStorageValue = UseLocalStorageListener("selectedValue");
  const embedRef = useRef(null);

  const embedConfig = {
    type: "report",
    id: reportId,
    embedUrl: PBItokens?.embedUrl,
    accessToken: PBItokens?.embedToken,
    tokenType: models.TokenType.Embed,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
    },
  };

  const updateSlicers = (filters) => {
    console.log("Start Update Filter");
    console.log("ref Data", embedRef);
    if (embedRef.current) {
      const report = embedRef.current;
      console.log("Report from Ref 27 ::", report);

      // report.on("loaded", () => {
      console.log("Report Status 000", report);

      report.getPages().then((pages) => {
        let activePage = pages[0];
        // Alternatively, find the active page:
        // let activePage = pages.filter(page => page.isActive)[0];

        activePage
          .getFilters()
          .then((res) => {
            console.log("Report Status 111", res);
          })
          .catch((err) => {
            console.log("Report Status 222", err);
          });

        activePage.getVisuals().then((visuals) => {
          console.log("@Report Status load", activePage, visuals);
          let slicer = visuals.filter((visual) => visual.type === "slicer");

          slicer.forEach((item, index) => {
            console.log("Report Status--02", item);
            item
              .getSlicerState()
              .then((res) => {
                console.log("Report Status--03", res, filters);
                filters.map((filter) => {
                  console.log("Report Status each Filter :", filter);
                  if (res && res.targets[0].column === filter.target.column) {
                    let tempFilter = {};
                    if (filter.values.length === 0) {
                      tempFilter.filters = [];
                      tempFilter.targets = res.targets[0];
                    } else {
                      tempFilter.filters = [filter];
                      tempFilter.targets = res.targets[0];
                    }

                    console.log("Setting tempFilter:", tempFilter);
                    console.log("res targets", res.targets[0]);
                    console.log("filter target", filter.target);
                    item
                      .setSlicerState(tempFilter)
                      .then((res) => {
                        console.log("Report Status--03 after Slice", res);
                      })
                      .catch((err) => {
                        console.log("Report Status--041", err);
                      });
                    return filter;
                  } else {
                    console.log("else");
                  }
                });
              })
              .catch((err) => {
                console.log("Report Status--042", err);
              });
          });
        });
      });
      // });
    }
    console.log("End Update Filter");
  };

  useEffect(() => {
    const fetchPBIToken = async () => {
      try {
        const idToken = localStorage.getItem("token");
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/power-bi/token`,
          {
            reportId: reportId,
            groupId: groupId,
          },
          {
            headers: {
              authorization: `Bearer ${idToken}`,
            },
          }
        );
        const data = await response.data.data;
        console.log("PBI token resp ::", data);
        setPBItokens(data);
      } catch (error) {
        console.error("Error while fetching PBI token...", error);
        setAlertMessage(error?.message);
        setAlertType("error");
        setAlertVisible(true);
      }
    };

    fetchPBIToken();
  }, []);

  useEffect(() => {
    if (embedRef.current) {
      embedRef.current.on("loaded", () => {
        console.log("Report fully loaded. Applying initial filters.");
        applyFilters();
      });
    }
  }, []);

  useEffect(() => {
    if (embedRef.current) {
      console.log("Filters changed. Reapplying filters.");
      applyFilters();
    }
  }, [localStorageValue]);

  const applyFilters = () => {
    console.log(localStorageValue, "currentCategorySector11");

    const marketFilter = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "IMM_SCORECARD_WITH_CATEGORY",
        column: "COUNTRY",
      },
      operator: "In",
      values: [localStorageValue],
      filterType: models.FilterType.Basic,
    };
    // setFilters([scorecardFilter]);
    updateSlicers([marketFilter]);
  };

  return (
    <div className="container-fullpage">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          gridColumn: "span 12",
        }}
      >
        <div>
          <div style={{ fontSize: 16, fontWeight: 600 }}>
            Multi-Year Initiatives By Lever
          </div>
          <div style={{ fontSize: 14, fontWeight: 400, color: "#6B6B6B" }}>
            Take a look at the overall status
          </div>
        </div>
      </div>

      <div className="pbi-chart">
        <PowerBIEmbed
          cssClassName={"reportClass"}
          embedConfig={embedConfig}
          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport;
            embedRef.current = embeddedReport;
            console.log("Embeded Report :::", embeddedReport);
          }}
        />
      </div>
      <CustomAlert
        message={alertMessage}
        type={alertType}
        visible={alertVisible}
        onClose={() => setAlertVisible(false)}
      />
    </div>
  );
};

export default MultiYearInitiativesByLever;
