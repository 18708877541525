import React, { Suspense, useState, useEffect } from "react";
import axios from "axios";
import { Route, Switch, useHistory, Redirect } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { SecureRoute, Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import Header from "./components/Header";
import InitiativeDetails from "./views/initiative_details/InitiativeDetails";
import Homepage from "./views/initiative/Homepage";
import ScorecardMainPage from "./views/scorecard/ScorecardMainPage";
import FVIHomepage from "./views/financial_aoc_vs_initiative/FVIHomepage";
import MarginMapHome from "./views/margin-map/MarginMapHome";
import CreateOpportunity from "./views/opportunity/CreateOpportunity";
import MarginWalkPage from "./views/multi-year-margin-walk/MarginWalkPage";
import PlaygroundMainPage from "./views/playground/PlaygroundMainPage";
import MultiYearInitiativesByLever from "./views/multi-year-initiatives-by-lever/MultiYearInitiativesByLever";
import PLReport from "./views/profit-loss-report/PLReport";
import CreateInitiative from "./views/initiative/CreateInitiative";
import MarginWalkGPDollar from "./views/margin-walk-gp-dollar/MarginWalkGPDollar";
// import UseLocalStorage from "../src/hooks/useLocalStorageListener";

const CustomSecureRoute = ({ component: Component, hasAccess, ...rest }) => {
  return (
    <SecureRoute
      {...rest}
      render={(props) =>
        hasAccess === "true" ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

function App() {
  const history = useHistory();
  const [oktaAuth, setOktaAuth] = useState(null);
  const [isAccess, setIsAccess] = useState(false);
  const isRGMUser = localStorage.getItem("isRGMUser");
  // const localStorageValue = UseLocalStorage("selectedType");

  useEffect(() => {
    const setupOktaAuth = async () => {
      let clientId = sessionStorage.getItem("clientId");
      let issuer = sessionStorage.getItem("issuer");
      if (!clientId || !issuer) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/okta-secrets`
          );
          clientId = response.data.data.OKTA_CLIENTID;
          issuer = response.data.data.OKTA_ISSUER;
          sessionStorage.setItem("clientId", clientId);
          sessionStorage.setItem("issuer", issuer);
        } catch (error) {
          console.error("Error fetching Okta configuration:", error);
          return;
        }
      }
      const authClient = new OktaAuth({
        clientId: clientId,
        issuer: issuer,
        responseType: "id_token",
        redirectUri: `${window.location.origin}/callback`,
        scopes: ["openid", "profile", "email", "groups"],
        pkce: true,
      });

      setOktaAuth(authClient);
    };

    setupOktaAuth();
  }, []);

  useEffect(() => {
    const fetchToken = async () => {
      const isUserAccess = localStorage.getItem("isUserAccess");
      if (isUserAccess) {
        setIsAccess(isUserAccess);
      }
    };
    setTimeout(fetchToken, 5000);
  }, []);

  if (!oktaAuth) {
    return (
      <div
        style={{
          position: "absolute",
          height: "100vh",
          width: "100%",
          background: "#ffffff90",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          left: 0,
          top: 0,
          zIndex: 99,
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <div style={{ background: "#f7f9fc" }}>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Header />
        <Suspense>
          <Switch>
            <SecureRoute
              exact
              path="/"
              render={(routeProps) => {
                return <Homepage props={routeProps} />;
              }}
            ></SecureRoute>

            <SecureRoute
              path="/initiative-details/:id/:sector/:driver/:lever/:year"
              exact={true}
              render={(routeProps) => {
                return <InitiativeDetails props={routeProps} />;
              }}
            />
            <SecureRoute
              path="/scorecard"
              render={(routeProps) => {
                return <ScorecardMainPage props={routeProps} />;
              }}
            />
            <Route
              path="/playground"
              render={(routeProps) => {
                return <PlaygroundMainPage props={routeProps} />;
              }}
            />
            <CustomSecureRoute
              path="/create-opportunity"
              component={CreateOpportunity}
              hasAccess={isAccess}
            />
            <CustomSecureRoute
              path="/create-initiatives"
              component={CreateInitiative}
              hasAccess={isRGMUser}
            />
            <SecureRoute
              path="/edit-opportunity/:id/:year"
              render={(routeProps) => {
                return <CreateOpportunity props={routeProps} />;
              }}
            />
            <SecureRoute
              path="/margin-walk-future-years"
              render={(routeProps) => {
                return <MarginWalkPage props={routeProps} />;
              }}
            />

            <SecureRoute
              path="/margin-walk-gp-dollar"
              render={(routeProps) => {
                return <MarginWalkGPDollar props={routeProps} />;
              }}
            />

            <SecureRoute
              path="/profit-loss-interrogation-report"
              render={(routeProps) => {
                return <PLReport props={routeProps} />;
              }}
            />

            <SecureRoute
              path="/margin-map"
              render={(routeProps) => {
                return <MarginMapHome props={routeProps} />;
              }}
            />
            <SecureRoute
              path="/financial-aoc-vs-initiative-margin-walk"
              render={(routeProps) => {
                return <FVIHomepage props={routeProps} />;
              }}
            />
            <Route
              path="/multi-year-initiatives-by-lever"
              render={(routeProps) => {
                return <MultiYearInitiativesByLever props={routeProps} />;
              }}
            />
            <Route path="/callback" component={LoginCallback} />
          </Switch>
        </Suspense>
      </Security>
    </div>
  );
}

export default App;
